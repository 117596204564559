import React from 'react';
import {useOutlet} from 'reconnect.js';
import * as User from 'rev.sdk.js/Actions/User';
import Config from '../../../data.json';
import AdminLayout from '../AdminLayout';
import {Helmet} from 'react-helmet';
import SiteNavBar from '../SiteNavBar';
import ProfileLayout from '../ProfileLayout';
import SiteFooter from '../SiteFooter';
import CheckoutLayout from '../CheckoutLayout';
import styled from 'styled-components';
import 'antd/dist/antd.less';
import './Layout.css';
import './custom.css';
import styledCss from './styledCss';
import customStyledCss from './customStyledCss';
import useFacebookPixel from '../../Hooks/useFacebookPixel';
import useGoogleAnalytics from '../../Hooks/useGoogleAnalytics';
import PleaseWait from '../PleaseWait';
import useIsInApp from 'rev.sdk.js/Hooks/useIsInApp';
import SelectStoreModal, {useSelectedStore} from '../SelectStoreModal';
import SelectOrderTypeModal from '../SelectOrderTypeModal';

function Layout(props) {
  const {children, location} = props;
  const isInApp = useIsInApp();
  const [dimension] = useOutlet('dimension');
  const [initialized, setInitialized] = React.useState(false);
  const selectedStore = useSelectedStore();
  const [
    forceOrderTypeModalVisible,
    setForceOrderTypeModalVisible,
  ] = React.useState(false);

  useFacebookPixel({pixelId: Config.pixelId});
  useGoogleAnalytics({gaId: Config.gaId, gtmId: Config.gtmId});

  React.useEffect(() => {
    const isAdmin = location.pathname.indexOf('admin') !== -1;

    const initialize = async (isAdmin) => {
      try {
        await User.autoLogin({admin: isAdmin});
      } catch (ex) {
        console.log('autoLogin ex', ex);
        User.logout();
      }

      setInitialized(true);
    };

    if (!initialized) {
      initialize(isAdmin);
    }
  }, [location.pathname, initialized]);

  React.useEffect(() => {
    if (
      props.location?.pathname !== '/' &&
      props.location?.pathname?.indexOf('/admin') === -1 &&
      props.location?.pathname?.indexOf('/pay') === -1 &&
      props.location?.pathname?.indexOf('/order') === -1 &&
      props.location?.pathname?.indexOf('/table') === -1 &&
      props.location?.pathname?.indexOf('/mobile/payment') === -1 &&
      props.location?.pathname?.indexOf('/pos/edit-') === -1 &&
      !(
        props.location?.pathname?.indexOf('/checkout') > -1 &&
        props.location?.search?.indexOf('payment_status=failed') > -1
      )
    ) {
      if (!selectedStore) {
        setForceOrderTypeModalVisible(true);
      } else {
        setForceOrderTypeModalVisible(false);
      }
    }
  }, [selectedStore, props.location]);

  const ecModals = (
    <>
      <SelectStoreModal />
      <SelectOrderTypeModal forceVisible={forceOrderTypeModalVisible} />
    </>
  );

  if (location.pathname.indexOf('admin') !== -1) {
    if (!initialized) {
      return <PleaseWait title="自動登入中" />;
    }

    return (
      <AdminLayout location={location}>
        <Helmet>
          <title>拉亞漢堡 Laya Burger</title>
          <meta
            name="google-site-verification"
            content="yctNZzOENc1T14tsYgmnemjRnp7ORLFnPuBJ9xbhNWU"
          />
          <meta
            name="description"
            content="拉亞漢堡早午餐，嚴選美味優質餐點，帶給大家全天候滿滿的能量，美好的一天就從拉亞漢堡開始。"
          />
          <meta
            property="image"
            content="https://preorder.laya.com.tw/laya-og.png"
          />
          <meta property="name" content="拉亞漢堡 Laya Burger" />
          <meta
            property="og:image"
            content="https://preorder.laya.com.tw/laya-og.png"
          />
          <meta property="og:title" content="拉亞漢堡 Laya Burger" />
          <meta
            property="og:description"
            content="拉亞漢堡早午餐，嚴選美味優質餐點，帶給大家全天候滿滿的能量，美好的一天就從拉亞漢堡開始。"
          />

          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          <script src="https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js"></script>
        </Helmet>
        {children}
      </AdminLayout>
    );
  } else if (location.pathname.indexOf('profile') !== -1) {
    if (!initialized) {
      return <PleaseWait title="自動登入中" />;
    }

    return (
      <Wrapper rwd={dimension.rwd}>
        <SiteNavBar location={location} />
        <ProfileLayout style={{flex: 1}} location={location}>
          {children}
        </ProfileLayout>
        <SiteFooter />
        {ecModals}
      </Wrapper>
    );
  } else if (location.pathname.indexOf('checkout') !== -1) {
    if (!initialized) {
      return <PleaseWait title="處理中..." />;
    }

    return (
      <>
        <Helmet>
          <title>拉亞漢堡 Laya Burger</title>
          <meta
            name="google-site-verification"
            content="yctNZzOENc1T14tsYgmnemjRnp7ORLFnPuBJ9xbhNWU"
          />
          <meta
            name="description"
            content="拉亞漢堡早午餐，嚴選美味優質餐點，帶給大家全天候滿滿的能量，美好的一天就從拉亞漢堡開始。"
          />
          <meta
            property="image"
            content="https://preorder.laya.com.tw/laya-og.png"
          />
          <meta property="name" content="拉亞漢堡 Laya Burger" />
          <meta
            property="og:image"
            content="https://preorder.laya.com.tw/laya-og.png"
          />
          <meta property="og:title" content="拉亞漢堡 Laya Burger" />
          <meta
            property="og:description"
            content="拉亞漢堡早午餐，嚴選美味優質餐點，帶給大家全天候滿滿的能量，美好的一天就從拉亞漢堡開始。"
          />
          <meta
            name="viewport"
            content="initial-scale=1.0, maximum-scale=1.0"
          />
        </Helmet>
        <Wrapper rwd={dimension.rwd}>
          {!isInApp && <SiteNavBar location={location} />}
          <CheckoutLayout style={{flex: 1}} location={location}>
            {children}
          </CheckoutLayout>
          {ecModals}
        </Wrapper>
      </>
    );
  } else if (location.pathname.indexOf('mobile') !== -1) {
    if (!initialized) {
      return <PleaseWait title="請稍後..." />;
    }

    return (
      <div>
        <Helmet>
          <title>拉亞漢堡 Laya Burger</title>
          <meta
            name="google-site-verification"
            content="yctNZzOENc1T14tsYgmnemjRnp7ORLFnPuBJ9xbhNWU"
          />
          <meta
            name="description"
            content="拉亞漢堡早午餐，嚴選美味優質餐點，帶給大家全天候滿滿的能量，美好的一天就從拉亞漢堡開始。"
          />
          <meta
            property="image"
            content="https://preorder.laya.com.tw/laya-og.png"
          />
          <meta property="name" content="拉亞漢堡 Laya Burger" />
          <meta
            property="og:image"
            content="https://preorder.laya.com.tw/laya-og.png"
          />
          <meta property="og:title" content="拉亞漢堡 Laya Burger" />
          <meta
            property="og:description"
            content="拉亞漢堡早午餐，嚴選美味優質餐點，帶給大家全天候滿滿的能量，美好的一天就從拉亞漢堡開始。"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>

        {children}
      </div>
    );
  } else if (location.pathname.indexOf('table') !== -1) {
    if (!initialized) {
      return <PleaseWait title="請稍後..." />;
    }

    return (
      <div>
        <Helmet>
          <title>拉亞漢堡 Laya Burger</title>
          <meta
            name="google-site-verification"
            content="yctNZzOENc1T14tsYgmnemjRnp7ORLFnPuBJ9xbhNWU"
          />
          <meta
            name="description"
            content="拉亞漢堡早午餐，嚴選美味優質餐點，帶給大家全天候滿滿的能量，美好的一天就從拉亞漢堡開始。"
          />
          <meta
            property="image"
            content="https://preorder.laya.com.tw/laya-og.png"
          />
          <meta property="name" content="拉亞漢堡 Laya Burger" />
          <meta
            property="og:image"
            content="https://preorder.laya.com.tw/laya-og.png"
          />
          <meta property="og:title" content="拉亞漢堡 Laya Burger" />
          <meta
            property="og:description"
            content="拉亞漢堡早午餐，嚴選美味優質餐點，帶給大家全天候滿滿的能量，美好的一天就從拉亞漢堡開始。"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>

        {children}
      </div>
    );
  }

  let showNavbar = true;
  let showFooter = true;

  if (props.location?.pathname?.indexOf('/product/') > -1) {
    showFooter = false;
  } else if (props.location?.pathname?.indexOf('/order/') > -1) {
    showFooter = false;
  } else if (props.location?.pathname?.indexOf('/pos/edit-') > -1) {
    showNavbar = false;
    showFooter = false;
  }

  return (
    <>
      <Helmet>
        <title>拉亞漢堡 Laya Burger</title>
        <meta
          name="google-site-verification"
          content="yctNZzOENc1T14tsYgmnemjRnp7ORLFnPuBJ9xbhNWU"
        />
        <meta
          name="description"
          content="拉亞漢堡早午餐，嚴選美味優質餐點，帶給大家全天候滿滿的能量，美好的一天就從拉亞漢堡開始。"
        />
        <meta
          property="image"
          content="https://preorder.laya.com.tw/laya-og.png"
        />
        <meta property="name" content="拉亞漢堡 Laya Burger" />
        <meta
          property="og:image"
          content="https://preorder.laya.com.tw/laya-og.png"
        />
        <meta property="og:title" content="拉亞漢堡 Laya Burger" />
        <meta
          property="og:description"
          content="拉亞漢堡早午餐，嚴選美味優質餐點，帶給大家全天候滿滿的能量，美好的一天就從拉亞漢堡開始。"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${Config.gaId}`}
        />
      </Helmet>
      <Wrapper rwd={dimension.rwd}>
        {showNavbar && <SiteNavBar bgColor="white" location={location} />}
        <div style={{flex: 1}}>{children}</div>
        {showFooter && <SiteFooter />}

        {ecModals}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  ${styledCss}
  ${customStyledCss}
`;

export default Layout;
