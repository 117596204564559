import {getOutlet} from 'reconnect.js';
import {navigate as nav} from 'gatsby';
import * as User from 'rev.sdk.js/Actions/User';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as PathUtil from 'rev.sdk.js/Utils/PathUtil';
import NavUrl from 'rev.sdk.js/Utils/NavUrl';
import Config from '../../data.json';
import * as jwt from 'rev.sdk.js/Utils/jwt';
import tracker from 'rev.sdk.js/Utils/Tracker';
import * as LocalCartUtil from '../Utils/LocalCartUtil';
import qs from 'query-string';
import diff, {isEmptyObject} from '../Utils/ObjectDiff';
import {message} from 'antd';

const req = ApiUtil.req;
const LoadingOutlet = getOutlet('loading');
const ApiHookOutlet = getOutlet('ApiUtil');
const UserOutlet = getOutlet('user');

ApiHookOutlet.update({
  ...ApiHookOutlet.getValue(),
  onJson: (url, payload, jsonResp) => {
    // a sample hook, you can do whatever you want here
    return jsonResp;
  },
  onError: async (url, payload, resp) => {
    if (url.indexOf('token=') > -1 && resp.status === 410) {
      console.log('onError try autoLogin');
      const user = UserOutlet.getValue();
      const isAdmin = user.grp.split(':').indexOf('admin') !== -1;
      const result = await User.autoLogin({admin: isAdmin});
      if (result) {
        console.log('onError autoLogin success, fetch resource again', result);
        return req(url, payload, {ignoreOnErrorHook: true});
      }
      console.log('onError autoLogin failure, throw original error', result);
      throw resp;
    }
  },
});

function transToQueryStr(params) {
  let query = '';
  if (typeof params === 'object') {
    query = Object.keys(params)
      .filter((key) => params[key] || params[key] === 0) // has value
      .reduce((e, key, idx) => {
        e = e + `${idx === 0 ? '?' : '&'}${key}=${params[key]}`;
        return e;
      }, '');
  }
  return query;
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function setLoading(loading, params) {
  const {message = '', visible = true} = params || {};
  setTimeout(() => {
    LoadingOutlet.update({loading: loading, message: message, visible});
  }, 0);
}

function L() {}

function gtag(eventType, event, payload) {
  let shouldContinue = true;
  // when true , would trigger the default ga behavior in rev.sdk.js
  return shouldContinue;
}

function fbq(eventType, event, payload) {
  let shouldContinue = true;
  // when true , would trigger the default fbq behavior in rev.sdk.js
  return shouldContinue;
}

async function navigate(nextRoute, options = {}) {
  const {message = '', loading = false, ...rest} = options;
  if (nextRoute instanceof NavUrl) {
    nextRoute = nextRoute.toString();
  }

  const currRoute = PathUtil.normalizedRoute();
  nextRoute = PathUtil.normalizedRoute(nextRoute);
  if (currRoute !== nextRoute) {
    if (options?.loading) {
      LoadingOutlet.update({
        message: options.message,
        loading: options.loading,
      });
      if (typeof options.loading === 'number') {
        setTimeout(() => {
          LoadingOutlet.update(false);
        }, options.loading);
      }
    }
    await nav(nextRoute, rest);
  } else {
    console.log('path not changed, ignore...');
  }
}

async function goBack() {
  nav(-1);
}

function adminCanAccess(user, options = {}) {
  return true;
}

/**
 * **************************************************
 * (client) JStorage powered product fetching APIs
 * **************************************************
 */

async function clientJStorageFetch(collection, {cat, sort, search, q, page}) {
  //"q" can defined custom query by project
  const catQuery = cat ? {labels: {$regex: cat}} : {};
  const searchQuery = search ? {$or: [{searchText: {$regex: search}}]} : {};
  const sortValue = sort ? [sort] : ['-created'];
  const pagingValue = page;
  const extraQueries = {};
  let projection = null;

  if (collection === 'product') {
    extraQueries.public = true;
    if (search) {
      searchQuery['$or'].push({name: {$regex: search}});
    }
  } else if (collection === 'Article_Default') {
    delete catQuery.labels;
    if (!cat) {
      catQuery.label = 'blog';
    } else {
      catQuery.label = {$regex: cat};
    }
    projection = {content: 0};
  }

  const resp = await JStorage.fetchDocuments(
    collection,
    {
      ...catQuery,
      ...searchQuery,
      ...extraQueries,
    },
    sortValue,
    pagingValue,
    projection, // if we're fetching Article, ignore the content
    {anonymous: true},
  );

  return resp;
}

const getDefaultCheckoutFormSpec = () => ({
  paymentSubTypes: [
    Cart.PAYMENT_SUBTYPE.default,
    Cart.PAYMENT_SUBTYPE.credit,
    Cart.PAYMENT_SUBTYPE.cod,
    Cart.PAYMENT_SUBTYPE.offline,
    Cart.PAYMENT_SUBTYPE.atm,
  ],
  logisticsTypes: [Cart.LOGISTICS_TYPE.cvs, Cart.LOGISTICS_TYPE.home],
  logisticsSubTypes: {
    [Cart.LOGISTICS_TYPE.cvs]: [
      Cart.LOGISTICS_SUBTYPE.famic2c,
      Cart.LOGISTICS_SUBTYPE.hilifec2c,
      Cart.LOGISTICS_SUBTYPE.unimartc2c,
    ],
    [Cart.LOGISTICS_TYPE.home]: [
      Cart.LOGISTICS_SUBTYPE.TCAT,
      Cart.LOGISTICS_SUBTYPE.ECAN,
    ],
  },
  invoiceCategories: [Cart.INVOICE_CATEGORY.b2c, Cart.INVOICE_CATEGORY.b2b],
  invoiceCarrierTypes: [
    Cart.INVOICE_CARRIER_TYPE.none,
    Cart.INVOICE_CARRIER_TYPE.ecpay,
    Cart.INVOICE_CARRIER_TYPE.cdc,
    Cart.INVOICE_CARRIER_TYPE.mobile,
  ],
});

function onCartLoaded(cart) {
  const checkoutFormSpec = getDefaultCheckoutFormSpec();

  const defaultUser = {
    buyer_name: cart.buyer_name || UserOutlet.getValue().data.name || '',
    buyer_email: cart.buyer_email || UserOutlet.getValue().data.email || '',
    buyer_phone: cart.buyer_phone || UserOutlet.getValue().data.phone || '',
    buyer_zip: cart.buyer_zip || UserOutlet.getValue().data.zip || '',
    buyer_city: cart.buyer_city || UserOutlet.getValue().data.city || '',
    buyer_district:
      cart.buyer_district || UserOutlet.getValue().data.district || '',
    buyer_address:
      cart.buyer_address || UserOutlet.getValue().data.address || '',
  };

  const updateConfig = {
    ...cart,
    ...defaultUser,
  };

  return {
    updateConfig,
    checkoutFormSpec,
  };
}

// 建立物流訂單 ( 通常會自行建立，此 api 用於意外發生，手動重新建立物流訂單 ）
async function createLogisticsOrder(id) {
  return await req(
    `${Config.apiHost}/order/logistics/create?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'post',
      data: {
        id: id,
      },
    },
  );
}

async function rebuild() {
  await req('https://api.netlify.com/build_hooks/615418bee44904a94bd7b4ab', {
    method: 'POST',
    data: {},
  });
}

async function fetchCustomResources(
  resource,
  {sort, keyword, filter, paging, extraQueries},
) {
  return null;
}

async function onLoginResult(err, result) {
  if (!err) {
    try {
      setLoading(true);
      const isAdmin = result.grp.split(':').indexOf('admin') !== -1;
      if (!isAdmin) {
        const queryKey = Config.jstoreVersion !== 'v1' ? 'owner' : 'id';
        const profile = await JStorage.fetchOneDocument('user_profile', {
          [queryKey]: UserOutlet.getValue().username,
        });
        const privateProfile = await User.getPrivateProfile();

        UserOutlet.update({
          ...UserOutlet.getValue(),
          data: {
            ...profile,
            email: privateProfile.email,
            points: privateProfile.points,
            provider: privateProfile.provider,
          },
        });

        await jwt.decodeToken(UserOutlet.getValue().token);
        tracker.login({user: UserOutlet.getValue()});
        await Cart.fetchCart();
      } else {
        // admin user
        let adminUser = await JStorage.fetchOneDocument('admin_user', {
          id: result.sub,
        });
        UserOutlet.update({
          ...UserOutlet.getValue(),
          permission: adminUser.permission,
          name: adminUser.username,
        });
      }
    } catch (ex) {
      console.warn('onLoginResult ex', ex);
    } finally {
      setLoading(false);
    }
  }
}

async function confirmOfflineOrder(id) {
  await req(
    `${Config.apiHost}/order/offline?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        id: id,
      },
    },
  );
}

async function createUser({username, password, name, birth}) {
  return await req(
    `${Config.apiHost}/user/create?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        username,
        password,
        name,
        birth,
      },
    },
  );
}

async function deleteUser({sub}) {
  return await req(
    `${Config.apiHost}/user/delete?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        sub,
      },
    },
  );
}

async function addUserPoint({points, event_name, user}) {
  return await req(
    `${Config.apiHost}/point/create?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        points,
        event_name,
        user,
      },
    },
  );
}

// not used
// add point by /point/create above
async function editUserPrivateProfile(id, points) {
  return await req(
    `${Config.apiHost}/user/points?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        user_id: id,
        points,
      },
    },
  );
}

async function addHistory({collection, previous, next}) {
  try {
    const user = getOutlet('user').getValue();

    if (previous === null) {
      await JStorage.createDocument('admin_history', {
        collection,
        document_id: next.id,
        action: 'create',
        editor: user.sub,
        editor_name: user.name,
      });
    } else {
      let diffObj = diff(previous, next);

      if (!isEmptyObject(diffObj)) {
        // write history
        let prev = Object.keys(diffObj).reduce((sum, key) => {
          sum[key] = previous[key];
          return sum;
        }, {});
        await JStorage.createDocument('admin_history', {
          collection,
          document_id: previous.id,
          action: 'update',
          editor: user.sub,
          editor_name: user.name,
          prev,
          content: diffObj,
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
}

async function addEventHistory({collection, eventName, content}) {
  const user = getOutlet('user').getValue();
  let previous;
  try {
    previous = await JStorage.fetchOneDocument('admin_history', {
      event_name: eventName,
    });
  } catch (e) {}

  content.event_name = eventName;
  try {
    if (!previous) {
      await JStorage.createDocument('admin_history', {
        collection,
        document_id: content.id,
        action: 'create',
        editor: user.sub,
        editor_name: user.name,
        event_name: eventName,
        content,
      });
    } else {
      await JStorage.updateDocument(
        'admin_history',
        {
          id: previous.id,
        },
        {
          collection,
          document_id: content.id,
          action: 'update',
          editor: user.sub,
          editor_name: user.name,
          event_name: eventName,
          content,
        },
      );
    }
  } catch (err) {
    console.log(err);
  }
}

async function onAdminFormSubmit({
  path,
  collection,
  instance,
  extValues,
  formData,
  formSchema,
  formUiSchema,
  primaryKey,
}) {
  if (path === '/admin/users' && !instance?.id) {
    // create
    try {
      let resp = await createUser(formData);
      console.log('resp', resp);
      navigate(path);

      message.success('新增成功');
    } catch (error) {
      console.log(error);
      if (error?.response?.error === 'user_create_error') {
        message.error('有重複的會員');
      } else {
        message.error('發生錯誤');
      }
      setLoading(false);
    }
    return true; //handled
  }

  return false;
}

async function onAfterAdminFormSubmit(
  {path, collection, instance, extValues, formData, primaryKey},
  updatedInstance,
) {
  console.log('DBG', path);
  if (
    path.indexOf('/admin/landing') > -1 ||
    path.indexOf('/admin/evt_current') > -1 ||
    path.indexOf('/admin/uber_config') > -1 ||
    path.indexOf('/admin/product_category') > -1 ||
    path.indexOf('/admin/variant_group') > -1 ||
    path.indexOf('/admin/article_category') > -1 ||
    path.indexOf('/admin/promotion_category') > -1 ||
    path.indexOf('/admin/product_attribute') > -1 ||
    path.indexOf('/admin/hotsearch') > -1 ||
    path.indexOf('/admin/meal') > -1 ||
    path.indexOf('/admin/config') > -1 ||
    path.indexOf('/admin/scratch_card_event') > -1 ||
    path.indexOf('/admin/draw_lots_event') > -1 ||
    path.indexOf('/admin/coupon_distribution_event') > -1 ||
    path.indexOf('/admin/pos_product_list') > -1
  ) {
    await JStorage.cacheDocuments('site', {}, null, null, null, undefined, {
      key: 'rev-site-cache.json',
    });
  }

  if (
    path.indexOf('/admin/stores') > -1 ||
    path.indexOf('/admin/store_pos_configs') > -1
  ) {
    // when creating new store, we have to put an extra 'uid' field
    if (path.indexOf('/admin/stores') > -1 && !instance) {
      await JStorage.updateDocument(
        'store',
        {id: updatedInstance.id},
        {uid: updatedInstance.id},
      );
    }

    await JStorage.cacheDocuments(
      'store',
      {},
      null,
      null,
      {stations: 0},
      undefined,
      {
        key: 'laya-store-cache.json',
      },
    );
  }

  if (['store', 'store_secret', 'store_promotion'].includes(collection)) {
    addHistory({
      collection,
      previous: instance,
      next: updatedInstance,
    });
  }
  if (
    [
      '/admin/evt_current',
      '/admin/scratch_card_event',
      '/admin/draw_lots_event',
      '/admin/coupon_distribution_event',
    ].includes(path)
  ) {
    addEventHistory({
      collection,
      content:
        path === '/admin/evt_current'
          ? {
              id: updatedInstance.id,
              evt_current: updatedInstance.evt_current,
            }
          : updatedInstance,
      eventName:
        path === '/admin/evt_current'
          ? updatedInstance.evt_current?.name
          : updatedInstance.settings?.name || updatedInstance.name,
    });
  }

  if (path.indexOf('/admin/products') > -1) {
    await JStorage.cacheDocuments(
      'product',
      {},
      ['_id', 'name'],
      null,
      null,
      undefined,
      {
        key: 'laya-product-cache.json',
      },
    );
  }

  return null;
}

function getReurl({title, description, image, redirectUrl}) {
  return `${Config.apiHost}/misc/reurl?title=${title}&image=${image}${
    description ? `&description=${description}` : ''
  }&redirect_url=${redirectUrl}`;
}

async function selectCVS({logisticsSubType}) {
  window.location.href = `${Config.apiHost}/misc/cvs-map?${qs.stringify({
    logistics_subtype: logisticsSubType,
    redirect_url: `${window.location.origin}${window.location.pathname}`,
  })}`;
}

// @sdk-next
function createCustomOrder(payload) {
  const {
    user_id,
    name,
    total,
    description,
    buyer_name,
    buyer_email,
    buyer_phone,
  } = payload;
  return req(
    `${Config.apiHost}/checkout/custom?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        user_id,
        name,
        total,
        description,
        buyer_name,
        buyer_email,
        buyer_phone,
        payment_subtype: 'offline',
      },
    },
  );
}

async function calcItemPrice(items) {
  const storeInfo = getOutlet('SelectStore').getValue().store;
  return req(`${Config.apiHost}/cart/items/calculate`, {
    method: 'POST',
    data: {
      config: storeInfo ? {store_id: storeInfo.id} : {},
      items,
    },
  });
}

async function calcCartPrice(cart, store, time) {
  const mappedCart = {config: {store_id: store?.id, time}, items: []};

  for (const item of cart.items) {
    const mappedItem = {
      product_id: item.product.id,
      variants: {...item.variants},
      qty: item.qty,
      note: item.note,
    };

    if (item.drink && item.drink.product) {
      mappedItem.drink = {
        product_id: item.drink.product.id,
        variants: {...item.drink.variants},
      };
    }

    if (item.add_on && item.add_on.product) {
      mappedItem.add_on = {
        product_id: item.add_on.product.id,
        variants: {...item.add_on.variants},
      };
    }

    mappedCart.items.push(mappedItem);
  }

  try {
    getOutlet('LayaCartExtra').update({loading: true});

    if (!store) {
      return await req(`${Config.apiHost}/cart/items/calculate`, {
        method: 'POST',
        data: mappedCart,
      });
    }

    return await req(`${Config.apiHost}/cart/status`, {
      method: 'POST',
      data: mappedCart,
    });
  } catch (ex) {
    throw ex;
  } finally {
    getOutlet('LayaCartExtra').update({loading: false});
  }
}

async function checkout({store, orderType, cart, method, prime, user, note}) {
  const mappedCart = {config: {...cart.config}, items: []};
  const apiPrefix = `${Config.apiHost}/checkout`;

  for (const item of cart.items) {
    const mappedItem = {
      product_id: item.product.id,
      variants: {...item.variants},
      qty: item.qty,
      note: item.note,
    };

    if (item.drink && item.drink.product) {
      mappedItem.drink = {
        product_id: item.drink.product.id,
        variants: {...item.drink.variants},
      };
    }

    if (item.add_on && item.add_on.product) {
      mappedItem.add_on = {
        product_id: item.add_on.product.id,
        variants: {...item.add_on.variants},
      };
    }

    mappedCart.items.push(mappedItem);
  }

  mappedCart.config = {
    store_id: store.id,
    time: orderType.time,
    buyer_name: user.name,
    buyer_phone: user.phone,
    buyer_email: user.email,
    mobile_carrier: user.mobile_carrier,
    uni_no: user.uni_no,
    donation_code: user.donation_code,
    note,
  };

  if (orderType.type === 'eat-here') {
    mappedCart.config.table = orderType.table;
  }

  let respOrder = null;

  let query = transToQueryStr({
    origin: 'web',
    ...(method === 'line' || method === 'jkopay' ? {device_type: 'web'} : {}),
  });
  console.log('query', query);

  if (method === 'cash') {
    respOrder = await req(`${apiPrefix}/request/cash${query}`, {
      method: 'POST',
      data: {
        cart: mappedCart,
        prime: prime,
        method: method,
      },
    });
  } else if (method === 'line') {
    respOrder = await req(`${apiPrefix}/request/linepay${query}`, {
      method: 'POST',
      data: {
        cart: mappedCart,
        prime: prime,
        method: method,
      },
    });
  } else if (method === 'jkopay') {
    respOrder = await req(`${apiPrefix}/request/jkopay${query}`, {
      method: 'POST',
      data: {
        cart: mappedCart,
      },
    });
  } else {
    respOrder = await req(`${apiPrefix}/request/tappay${query}`, {
      method: 'POST',
      data: {
        cart: mappedCart,
        prime: prime,
        method: method,
      },
    });

    // this is a special case, because credit by tappay need 3D verify
    if (method === 'credit') {
      return respOrder;
    }
  }

  // line have not created order yet
  if (method !== 'line' && method !== 'jkopay') {
    if (respOrder) {
      const LocalOrdersOutlet = getOutlet('LocalOrders');
      // let it looks like a jstorage fetched one
      respOrder.id = respOrder._id.$oid;
      delete respOrder._id;
      const nextData = [...LocalOrdersOutlet.getValue(), respOrder];
      LocalOrdersOutlet.update(nextData);
      LocalCartUtil.setLocalOrders(nextData);
    }
  }

  return respOrder;
}

async function fetchOrderDetail({order_number}) {
  return req(`${Config.apiHost}/order/detail`, {
    method: 'POST',
    data: {
      query: {
        order_number,
      },
    },
  });
}

async function sendPublicNotifications(data) {
  const token = UserOutlet.getValue()?.token;
  const query = token ? `?token=${token}` : '';
  return req(`${Config.apiHost}/notification/public${query}`, {
    method: 'POST',
    data,
  });
}

async function fetchProducts({cat, onlyPublic = true} = {}) {
  try {
    const invalidProductNames = await fetchInvalidProductNames();
    const allProducts = (
      await req(`${Config.siteCacheBaseUrl}/laya-product-cache.json`, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
    )
      .filter((product) => (onlyPublic ? product.public : true))
      .map((product) => {
        if (invalidProductNames.indexOf(product.name) > -1) {
          return {
            ...product,
            $invalid: true,
          };
        }
        return product;
      })
      .map((product) => {
        const variantGroups = getOutlet('variantGroups').getValue();
        const variantGroupOverride = {};
        if (product.group) {
          const vg = variantGroups.find((i) => i.name === product.group);
          if (vg) {
            variantGroupOverride.variants = vg.variants || [];
          }
        }

        return {
          ...product,
          ...variantGroupOverride,
          $invalidVariants: invalidProductNames,
          id: product._id.$oid,
        };
      });

    if (!cat) {
      return allProducts;
    } else {
      return allProducts.filter((product) => {
        return (product.labels || []).find((label) => label.indexOf(cat) > -1);
      });
    }
  } catch (ex1) {
    console.warn('[AppContext] product cache miss...', ex1);
  }

  if (!cat) {
    return JStorage.fetchAllDocuments('product').filter(
      (product) => product.public,
    );
  }

  return JStorage.fetchAllDocuments('product', {
    labels: {
      $regex: cat,
    },
  }).filter((product) => product.public);
}

async function fetchAllDrinks() {
  const allProducts = await fetchProducts();
  return allProducts.filter((p) => {
    for (const label of p.labels || []) {
      if (label.indexOf('飲品') > -1) {
        return true;
      }
    }
    return false;
  });
}

async function fetchStores({allowAll = false} = {}) {
  try {
    const allStores = (
      await ApiUtil.req(`${Config.siteCacheBaseUrl}/laya-store-cache.json`, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
    )
      .filter((store) => allowAll || store.public === undefined || store.public)
      .map((store) => {
        return {
          ...store,
          id: store._id.$oid,
        };
      });

    return allStores;
  } catch (ex1) {
    console.warn('[AppContext] store cache miss...', ex1);
  }

  return JStorage.fetchAllDocuments('store');
}

async function adminCancelOrder(id) {
  return req(
    `${Config.apiHost}/order/status?token=${UserOutlet.getValue().token}`,
    {
      method: 'PUT',
      data: {
        id,
        status: 'cancelled',
      },
    },
  );
}

async function adminRefundOrder({order_number}) {
  return req(
    `${Config.apiHost}/order/refund/${order_number}?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {},
      /*
      只有local_credit & ezcard可以帶入，其他由後端產生
      refund_detail -> deprecated 
      refund_info: {
        detail: {},
        time: timestamp
      }
      */
    },
  );
}

async function adminRefreshStockCacheByStore(storeId) {
  return ApiUtil.req(
    `${Config.apiHost}/store/pos/stock_cache?token=${
      UserOutlet.getValue()?.token
    }`,
    {
      method: 'POST',
      data: {
        id: storeId,
      },
    },
  );
}

async function fetchInvalidProductNames() {
  try {
    const store = getOutlet('SelectStore').getValue()?.store;

    console.log('store', store);

    if (store) {
      return await ApiUtil.req(
        `${Config.apiHost}/store/invalid-products?store_id=${store.id}`,
      );
    }
  } catch (ex) {
    // bypass
  }
  return [];
}

async function createPosUser({username, password, store_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/pos_user/create?token=${UserOutlet.getValue()?.token}`,
    {
      method: 'POST',
      data: {
        username,
        password,
        store_id,
      },
    },
  );
}

async function adminResetPosUserPassword({username, password, store_id}) {
  return ApiUtil.req(
    `${Config.apiHost}/pos_user/reset/password/admin?token=${
      UserOutlet.getValue()?.token
    }`,
    {
      method: 'POST',
      data: {
        username,
        password,
        store_id,
      },
    },
  );
}

async function adminDeletePosUserPassword(user_id) {
  return ApiUtil.req(
    `${Config.apiHost}/pos_user/delete?token=${UserOutlet.getValue()?.token}`,
    {
      method: 'POST',
      data: {
        user_id,
      },
    },
  );
}

async function fetchCurrentEvent() {
  return (
    await ApiUtil.req(Config.siteCacheUrl, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })
  ).find((cfg) => cfg.name === 'landing')?.evt_current;
}

async function fetchMyStamps({event_name, owner}) {
  return JStorage.fetchAllDocuments('stamp', {
    event_name,
    owner,
  });
}

async function loginPosUser(params) {
  return ApiUtil.req(`${Config.apiHost}/pos_user/login`, {
    method: 'POST',
    data: {
      username: params.username,
      password: params.password,
      store_id: params.store_id,
    },
  });
}

async function syncWeibyOrder(id) {
  return ApiUtil.req(
    `${Config.apiHost}/order/${id}/weiby-sync?token=${
      UserOutlet.getValue()?.token
    }`,
    {
      method: 'POST',
    },
  );
}

export {
  delay,
  setLoading,
  L,
  navigate,
  goBack,
  adminCanAccess,
  clientJStorageFetch,
  fetchCustomResources,
  onLoginResult,
  addHistory,
  addEventHistory,
  onAdminFormSubmit,
  onAfterAdminFormSubmit,
  onCartLoaded,
  createLogisticsOrder,
  rebuild,
  getReurl,
  confirmOfflineOrder,
  createUser,
  deleteUser,
  addUserPoint,
  editUserPrivateProfile,
  createCustomOrder,
  selectCVS,
  gtag,
  fbq,
  calcItemPrice,
  calcCartPrice,
  checkout,
  fetchOrderDetail,
  sendPublicNotifications,
  fetchProducts,
  fetchAllDrinks,
  fetchStores,
  adminCancelOrder,
  adminRefundOrder,
  adminRefreshStockCacheByStore,
  fetchInvalidProductNames,
  createPosUser,
  adminResetPosUserPassword,
  adminDeletePosUserPassword,
  fetchCurrentEvent,
  fetchMyStamps,
  loginPosUser,
  syncWeibyOrder,
};
