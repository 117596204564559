import React from 'react';
import {Button, Input, Modal, Table, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';

getNewOutlet('admin-search-coupon-template-modal', null, {autoDelete: false});

export function showAdminSearchCouponTemplateModal(data) {
  getOutlet('admin-search-coupon-template-modal').update(data);
}

const fetchRecords = async (
  keyword,
  columns,
  paging = {offset: 0, limit: 4},
) => {
  try {
    AppActions.setLoading(true);
    return Jstorage.fetchDocuments(
      'coupon_template',
      {
        $or: columns
          .filter((c) => c.key)
          .map((column) => ({
            [column.key]: {$regex: keyword},
          })),
      },
      null,
      paging,
    );
  } catch (err) {
    message.error('發生錯誤。');
  } finally {
    AppActions.setLoading(false);
  }
};

export default function AdminSearchCouponTemplateModal(props) {
  const [data, setData] = useOutlet('admin-search-coupon-template-modal');
  const [keyword, setKeyword] = React.useState('');
  const [records, setRecords] = React.useState(null);
  // const [_selectedRows, _setSelectedRows] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    total: 0,
    pageSize: 4,
  });

  const columns = [
    {
      key: 'name',
      title: '名稱',
      render: (record) => (
        <div>
          <div>{record.name}</div>
          <div style={{fontSize: 10, color: '#bbb'}}>{record.id}</div>
        </div>
      ),
    },
    {
      key: 'event_name',
      title: '活動',
      dataIndex: 'event_name',
    },
    {
      key: '',
      title: '',
      dataIndex: '',
      render: (_, item) => {
        return (
          <Button
            onClick={() => {
              data.onChange(item.id);
              onClose();
            }}>
            選擇
          </Button>
        );
      },
    },
  ];

  function onClose() {
    setKeyword(null);
    setRecords(null);
    // _setSelectedRows([]);
    setData(null);
  }

  return (
    <Modal
      visible={!!data}
      onCancel={onClose}
      footer={null}
      width={1000}
      closable={false}
      maskClosable={true}
      destroyOnClose={true}>
      <div>
        <Input.Search
          style={{marginBottom: 10}}
          placeholder="請輸入關鍵字"
          allowClear
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={async () => {
            let {total, results} = await fetchRecords(keyword, columns, {
              offset: 0,
              limit: pagination.pageSize,
            });
            setRecords(results);
            setPagination({...pagination, total});
          }}
        />

        <Table
          style={{marginBottom: 10}}
          scroll={{x: 500, y: 300}}
          pagination={{
            ...pagination,
            onChange: async (page, pageSize) => {
              let {total, results} = await fetchRecords(keyword, columns, {
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
              setRecords(results);
              setPagination({...pagination, total});
            },
          }}
          /*
          rowKey={(record) => record.owner}
          rowSelection={{
            type: 'radio',
            onChange: (selectedRowKeys, selectedRows) => {
              _setSelectedRows(selectedRows);
            },
          }}
          */
          columns={columns}
          dataSource={records}
        />
      </div>
    </Modal>
  );
}
